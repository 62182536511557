<template>
  <div>
    <svg ref="barcode"></svg>
  </div>
</template>
  
  <script>
import JsBarcode from "jsbarcode";

export default {
  props: {
    value: {
      type: String,
      required: true,
    },
    width: {
      type: Number,
      default: 2,
    },
    height: {
      type: Number,
      default: 65,
    },
    lineColor: {
      type: String,
      default: "#000",
    },
    displayValue: {
      type: Boolean,
      default: true,
    },
    format: {
      type: String,
      default: "CODE128",
    },
  },
  mounted() {
    this.generateBarcode();
  },
  methods: {
    generateBarcode() {
      JsBarcode(this.$refs.barcode, this.value, {
        format: this.format, // Specify the barcode format
        lineColor: this.lineColor,
        width: this.width,
        height: this.height,
        displayValue: this.displayValue,
      });
    },
  },
  watch: {
    value() {
      this.generateBarcode();
    },
  },
};
</script>
  
  <style scoped>
svg {
  max-width: 100%;
}
</style>  